<template>
  <el-tabs v-model="activeName" :tab-position="tabPosition">
    <el-tab-pane label="隐私政策" name="policy">
      <policy />
    </el-tab-pane>
    <el-tab-pane label="用户协议" name="agreement">
      <agreement />
    </el-tab-pane>
    <el-tab-pane label="项目背景" name="background">
      <div>
        <div class="tab1">
          <div class="tltle">项目背景</div>
          <div class="tab1_text">
            <span>百万麒麟APP是一款通过利用互联网短视频的形式做到知识网络化、大众化的平台软件，每一个用户都有潜力成为创作者，分享自己的知识或者一技之长，让每一个观看视频者从中获取知识。作为新时代教育的开拓者，百万麒麟APP提供了有海量丰富的知识内容供用户挑选，同时给予用户极大的自由度选择希望学习的方向，创造多种互动模式，真正做到知识共享，传递正能量，提升每一个人的学习兴趣。</span>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="龙币规则" name="rule">龙币规则</el-tab-pane>
    <el-tab-pane label="视频准则" name="criterion">
      <div>
        <div class="tab1">
          <div class="tltle">视频准则</div>
          <div class="tab1_text">
            <p>1、创作者视频上传准则</p>
            <span>
              1）、创作者需将自己想要与社会公众分享的知识或技能录制成15分钟一集的视频（不低于10分钟，不高于15分钟），并自行进行剪辑。
              <br />2）、在15分钟的视频中需要剪辑出30秒～60秒的精华部分作为高光时刻单独上传，以便节约用户的寻找时间，提高用户体验度。
              <br />3）、创作者用户在使用视频上传服务时，必须遵守中华人民共和国相关法律法规的规定，不利用本服务进行任何违法或不正当的活动，包括但不限于上传包含下列内容之一的视频内容：
              <ul>
                <li>
                  <span></span>反对宪法确定的基本原则；
                </li>
                <li>
                  <span></span>危害国家统一、主权和领土完整的；
                </li>
                <li>
                  <span></span>泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；
                </li>
                <li>
                  <span></span>煽动民族仇恨，民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；
                </li>
                <li>
                  <span></span>宣扬邪教、迷信的；
                </li>
                <li>
                  <span></span>扰乱社会秩序，破坏社会稳定的；
                </li>
                <li>
                  <span></span>诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；
                </li>
                <li>
                  <span></span>侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；
                </li>
                <li>
                  <span></span>危害社会公德，损害民族优秀文化传统的；
                </li>
                <li>
                  <span></span>非法的广播电视频道、视听节目网站提供的非法视频内容；
                </li>
                <li>
                  <span></span>有关法律、行政法规和国家规定禁止的其他内容。
                </li>
              </ul>4）、所有创作者视频将通过人工智能筛选，初级人工审核，终极人工审核三级审核模式，凡是特殊稿件、疑难稿件，将会进入特别评委会审核。
            </span>
            <p>2、创作者收入机制与权益</p>
            <span>
              1）、创作者视频经过审核上传成功后，将获得公司补贴，以每10--15分钟为单位为一集，由评审委员会根据视频内容，确定质量等级，进行300~3000元/集的对应补贴。
              <br />2）、创作者可以自定义设置自己上传分享给用户的全集视频的付费观看价格，价格区间为（0.2~1元人民币），该收益中如创作者与平台签定的是版权唯一平台发布协议，创作者可以获得该收益的50%，若创作者与平台签署为非唯一平台发布协议（即多平台发布协议），则创作者可获得该收益的30%。
              <br />3）、如创作者与平台签定版权唯一平台发布协议，创作者可以获得平台粉丝打赏收益的50%，若创作者与平台签订协议为非唯一平台发布协议（即多平台发布协议），则创作者可获得平台粉丝打赏收益的30%。
              <br />4）、创作者对于上传至本平台的视频享有终生版权收益权。
              <br />5）、创作者对于上传至本平台的视频享有版权收益指定收益人权和指定收益人继承权。
              <br />6）、创作者将自己的知识或技术分享给社会公众学习，所有用户除付费观赏外，还可以用两个正能量兑换一节课（创作者的视频）。创作者正能量积累至99999个可以兑换一枚龙币。
              <br />
            </span>
            <p>3、视频采集机制</p>
            <span>
              1）、视频采集方向：情感社交、心理学、管理学、法律专题、财务知识、职场科普、穿搭时尚、语言学习、化妆技巧及化妆案例（真实性）、体育运动、室内设计、艺术赏析、读书演讲（诗书名著）、美食烘培、旅游达人、其他类（不包括以上所列方向）
              <br />2）、视频采集渠道：由创作者原创投稿；
              <br />创作者视频价格：每集（10--15分钟）定价300~1000元，特别优质的视频可特别定价每集1000~3000元。
            </span>
          </div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import policy from './policy.vue';
import agreement from './agreement.vue';
export default {
  props: ['propsId'],
  data() {
    return {
      tabPosition: 'left',
      activeName: 'policy'
    };
  },
  created() {
    this.isShow();
  },
  methods: {
    isShow(newVal = '') {
      const data = ['policy', 'agreement', 'background', 'rule', 'criterion'];
      const propsId = this.propsId;
      this.activeName = data.includes(propsId) ? propsId : newVal;
    }
  },
  computed: {
    watchId() {
      return this.$route.params.id;
    }
  },
  components: { policy, agreement },
  watch: {
    watchId() {
      this.isShow('policy');
    }
  }
};
</script>

<style lang="scss">
.el-tab-pane {
  .tab1 {
    padding: 0 40px;
    .tltle {
      font-size: 40px;
      font-weight: bold;
      color: #000000;
      text-align: center;
      margin: 40px auto;
    }
    &_text {
      p {
        font-size: 24px;
        font-weight: bold;
        color: #222222;
      }
      span {
        font-size: 18px;
        color: #222222;
        display: block;
        padding: 20px 0 40px;
        line-height: 22px;
      }
      ul {
        padding-bottom: 36px;
        li {
          padding-top: 16px;
          font-size: 18px;
          color: #000000;
          position: relative;
          display: flex;
          line-height: 22px;
        }
        li > span {
          min-width: 10px;
          width: 10px;
          height: 10px;
          background-color: #000000;
          border-radius: 50%;
          display: block;
          padding: 0;
          margin-top: 8px;
          margin-right: 14px;
        }
      }
    }
  }
}
</style>
