<template>
  <div class="home5">
    <Tabs :propsId="id"></Tabs>
  </div>
</template>

<script>
import Tabs from '../components/public/Tabs';
export default {
  name: 'home5',
  props: ['id'],
  components: { Tabs }
};
</script>
<style lang="scss">
.headers {
  height: auto !important;
}
.el-tabs__nav {
  & > div {
    height: auto;
  }
}
.home5 {
  min-height: 100vh;
  .el-tabs__nav {
    .is-active {
      font-weight: bold;
      background-color: #fff8e6;
    }
    .el-tabs__item.is-left {
      text-align: left;
    }
  }
  .el-tabs {
    min-height: 100vh;
    display: flex;
    position: relative;
  }
  .el-tabs__nav-wrap {
    height: 100%;
  }
  //.el-tabs__nav-wrap::after {
 //   width: $wb2 !important;
 // }
  .el-tabs__active-bar {
    //width: $rw4 !important;
    background-color: #ffb600;
  }
  .el-tabs__item {
    line-height: 56px;
    color: #000000;
    font-size: 20px;
    padding-left: 30px;
  }
  .el-tabs__header {
    width: 230px;
    height: 56px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-right: 20px !important;
  }
  .el-tabs__content {
    width: 1246px;
    margin-left: 254px;
    border: 1px solid #c1c1c1;
  }
}
</style>
<style scoped lang="scss">
.home5 {
  padding: 40px 100px 80px 240px;
}
</style>
